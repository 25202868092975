import React from 'react'
import icon from '../assets/Untitled-3.jpg'
import szoveg from '../assets/not-yet-but-soon.svg'

function Main() {
  return (
    <div className='w-full h-screen flex flex-col justify-center items-center'>
      <img src={ icon } alt="NOTESED" className='h-[100px] w-[100px]'/>
      <img src={ szoveg } alt=""  className='w-[200px] mt-10'/>
    </div>
  )
}

export default Main
